import styled from "styled-components";

const GlassBackground = styled.div`
  padding: 24px 16px;
  border-radius: 16px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  height: fit-content;
`;

export default GlassBackground;