function AppLogo() {
  return (
    <svg
      width="152"
      height="32"
      viewBox="0 0 152 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_402_16525)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.6519 0.0795789L22.2337 0.00268555C21.7824 0.00268555 21.5567 0.487388 21.873 0.774365L29.2541 7.4174C29.73 7.84718 30.3778 8.08747 31.0548 8.08747H57.7745C58.2258 8.08747 58.4515 7.60003 58.1337 7.31579L50.3243 0.328109C50.1477 0.170203 49.9052 0.0782058 49.6565 0.0782058H49.6549L49.6519 0.0795789Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.8171 16.6241H59.0578V31.1487C59.0578 31.6183 58.6326 32 58.106 32H40.4884C40.1062 32 39.9112 31.5867 40.1814 31.3437L48.2395 24.047H50.0433V16.62H48.2825L50.0433 15.045L53.3286 12.1052L54.546 11.0164L60.8187 16.6268V16.6255L60.8171 16.6241Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.01728 19.0435V7.9516H14.1463L31.9896 23.9111H0.903901C0.596867 23.9111 0.441815 24.2434 0.658274 24.4397L8.24816 31.2297C8.73941 31.6691 9.40721 31.919 10.1026 31.9204L35.0584 31.9959C35.6157 31.9959 36.153 31.7995 36.549 31.448L44.2111 24.6169C44.5826 24.2846 44.5826 23.7463 44.2111 23.414L18.956 0.821111C18.368 0.295216 17.5713 0 16.7392 0H0.953027C0.426463 0 0.0012207 0.380348 0.0012207 0.851319V19.0462C0.0012207 19.5158 0.426463 19.8975 0.953027 19.8975H8.06854C8.59357 19.8975 9.02035 19.5172 9.02035 19.0462L9.01882 19.0435H9.01728Z"
          fill="#039EF2"
        />
        <path
          d="M69.4786 26.4268C67.7592 26.4268 66.6462 25.607 66.6462 23.8714V23.7685C66.6462 22.0329 67.8345 21.2131 69.6598 21.2131C70.2477 21.2131 70.8388 21.2722 71.2963 21.3958V22.1276C70.7958 21.9889 70.3045 21.9299 69.6828 21.9299C68.3073 21.9299 67.661 22.5299 67.661 23.7822V23.8481C67.661 25.1072 68.249 25.7086 69.4863 25.7086C69.9039 25.7086 70.2477 25.6867 70.5594 25.6276V24.1639H69.1593L69.3635 23.4458H71.5741V26.1549C70.7805 26.3444 70.2493 26.4254 69.4786 26.4254V26.4282V26.4268Z"
          fill="#039EF2"
        />
        <path
          d="M78.6298 21.2502H79.6369L81.9949 26.3829H80.8819L80.4567 25.3874H77.8131L77.3802 26.3829H76.2672L78.6329 21.2502H78.6298ZM80.1604 24.6775L79.138 22.2965L78.0987 24.6775H80.1604Z"
          fill="#039EF2"
        />
        <path
          d="M87.9084 21.2585L89.9149 25.2639L91.9137 21.2585H93.1019V26.3843H92.0948V22.7799L90.3018 26.3843H89.5326L87.7411 22.7799V26.3843H86.734V21.2585H87.913H87.9084Z"
          fill="#039EF2"
        />
        <path
          d="M99.2655 21.2585V26.3843H98.2493V21.4865L99.2655 21.2585Z"
          fill="#039EF2"
        />
        <path
          d="M105.265 21.2585L108.228 24.8767V21.2585H109.243V26.3843H108.366L105.411 22.7593V26.3843H104.396V21.2585H105.263H105.265Z"
          fill="#039EF2"
        />
        <path
          d="M117.035 26.4268C115.316 26.4268 114.203 25.607 114.203 23.8714V23.7685C114.203 22.0329 115.391 21.2131 117.216 21.2131C117.804 21.2131 118.395 21.2722 118.853 21.3958V22.1276C118.352 21.9889 117.861 21.9299 117.239 21.9299C115.864 21.9299 115.218 22.5299 115.218 23.7822V23.8481C115.218 25.1072 115.809 25.7086 117.043 25.7086C117.46 25.7086 117.804 25.6867 118.116 25.6276V24.1639H116.716L116.922 23.4458H119.132V26.1549C118.337 26.3444 117.807 26.4254 117.037 26.4254L117.034 26.4282L117.035 26.4268Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.1497 5.57486H66.6476V16.0008H77.1497C79.2928 16.0008 81.0475 14.4368 81.0475 12.5145V9.05978C81.0475 7.13195 79.2866 5.57349 77.1497 5.57349V5.57486ZM70.0772 8.64236H76.9041C77.2986 8.64236 77.6164 8.92659 77.6164 9.27947V12.2975C77.6164 12.6477 77.2971 12.9347 76.9041 12.9347H70.0772V8.64098V8.64373V8.64236Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.9464 5.57495H85.8849L81.0476 16.0009H84.7581L88.1723 8.64245V7.87626H88.6559V8.64245L92.0717 16.0009H95.7852L90.9464 5.57495Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.9354 5.57495V8.64245H99.4191V16.0009H102.849V8.64245H108.335V5.57495H93.9354Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.449 8.26622C151.28 7.6346 150.924 7.05927 150.41 6.59791C149.892 6.13517 149.248 5.81387 148.543 5.66832C148.256 5.60653 147.955 5.57495 147.656 5.57495H137.154V16.0009H140.583V13.5471H147.656C147.789 13.5471 147.927 13.5389 148.061 13.5293C148.225 13.5156 148.388 13.4895 148.543 13.4565C149.249 13.3096 149.892 12.9869 150.41 12.5269C150.926 12.0656 151.282 11.4902 151.448 10.8586V10.8504L151.452 10.8408C151.517 10.5854 151.552 10.3245 151.552 10.0609V9.05987C151.552 8.79074 151.514 8.52573 151.451 8.26622H151.449ZM140.577 8.64245H147.407C147.802 8.64245 148.119 8.92668 148.119 9.27956V9.84528C148.119 10.1954 147.8 10.481 147.407 10.481H140.58V8.64245H140.579H140.577Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.428 5.57495V5.8839L132.424 12.9347H125.598C125.207 12.9347 124.886 12.6491 124.886 12.3004V5.57632H121.459V13.0418C121.587 13.8012 121.997 14.5138 122.619 15.0466C123.24 15.5848 124.054 15.9212 124.912 16.0009H124.931L131.521 16.0022L132.503 15.0301V16.0022H135.858V5.57632H132.431H132.43L132.428 5.57495Z"
          fill="#039EF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.295 5.57495H111.234L106.395 16.0009H110.108L113.521 8.64245V7.87626H114.005V8.64245L117.422 16.0009H121.133L116.295 5.57495Z"
          fill="#039EF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_402_16525">
          <rect width="151.552" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppLogo;