enum TextSize {
    TS12,
    TS14,
    TS16,
    TS18,
    TS20,
    TS24,
    TS30,
    TS36,
    TS48,
    TS60,
    TS72,
    TS132,
}

export enum FontFamily {
    LT_RENOVATE = "LTRenovate",
    SATOSHI = "Satoshi",
}

export enum TextStyle {
    NORMAL = "normal",
    ITALIC = "italic",
}


export default TextSize;