import MainNavigation from "../../components/Navigation/MainNavigation";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import SectionNine from "./SectionNine";
import { ScrollToTopButton } from "../../components/Button/Button";

function LandingPage() {
  return (
    <div>
      <ScrollToTopButton />
      <MainNavigation />
      <div className="container-fluid">
        <div className="row">
          <SectionOne />
          <SectionTwo id="Features" />
          <SectionThree id="Company" />
          <SectionFour id="Shop" />
          <SectionFive id="Benefits" />
          <SectionSix />
          <SectionSeven id="Partners" />
          <SectionEight id="Contact" />
          <SectionNine />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
