import styled from "styled-components";
import { FlexAlignItems, VerticalLayout } from "../../components/Layout";
import { BorderedText, GradientText } from "../../components/Text/Text";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";
import TextSize from "../../components/Text/TextEnum";
import { useTranslation } from "react-i18next";
import { Coins } from "./Definitions";

const SectionOne = () => {
  const themeColors = useThemeColors();
  const { t } = useTranslation();

  return (
    <SectionOneWrapper>
      <Coins
        src="/images/coin.png"
        topDesktop="135px"
        leftDesktop="120px"
        widthDesktop="64px"
        heightDesktop="85px"
        topMobile="35px"
        leftMobile="35px"
        widthMobile="20px"
        heightMobile="28px"
      />
      <Coins
        src="/images/coin.png"
        topDesktop="425px"
        leftDesktop="48px"
        widthDesktop="45px"
        heightDesktop="60px"
        topMobile="163px"
        leftMobile="-7px"
        widthMobile="25px"
        heightMobile="35px"
      />

      <Coins
        src="/images/coin.png"
        topDesktop="275px"
        rightDesktop="125px"
        widthDesktop="64px"
        heightDesktop="85px"
        topMobile="140px"
        rightMobile="-9px"
        widthMobile="30px"
        heightMobile="40px"
      />

      <VerticalLayout marginTop="32px" alignItems={FlexAlignItems.CENTER}>
        <BorderedText
          themeColors={themeColors}
          fontSize={TextSize.TS24}
          fontSizeLg={TextSize.TS30}
          fontWeight={800}
        >
          {t("sectionOne.subTitle")}
        </BorderedText>
        <GradientText
          themeColors={themeColors}
          fontSize={TextSize.TS72}
          fontSizeLg={TextSize.TS132}
          fontWeight={800}
        >
          {t("sectionOne.title")}
        </GradientText>
      </VerticalLayout>
    </SectionOneWrapper>
  );
};

export default SectionOne;

const SectionOneWrapper = styled(VerticalLayout)`
  position: relative;
  width: 100%;
  height: calc(100vw * 0.8);
  max-height: 751px;
  padding: 0 16px;
  background-image: url("/images/section-one-bg-optimized.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  text-align: center;
`;
