export const Phone = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" fill="#039EF2" />
      <path
        d="M34.0014 28.9201V31.9201C34.0025 32.1986 33.9455 32.4743 33.8339 32.7294C33.7223 32.9846 33.5587 33.2137 33.3535 33.402C33.1483 33.5902 32.906 33.7336 32.6421 33.8228C32.3783 33.912 32.0988 33.9452 31.8214 33.9201C28.7442 33.5857 25.7884 32.5342 23.1914 30.8501C20.7752 29.3148 18.7267 27.2663 17.1914 24.8501C15.5014 22.2413 14.4496 19.2711 14.1214 16.1801C14.0964 15.9036 14.1293 15.6249 14.2179 15.3617C14.3065 15.0986 14.449 14.8568 14.6362 14.6517C14.8234 14.4466 15.0512 14.2828 15.3052 14.1706C15.5592 14.0584 15.8337 14.0004 16.1114 14.0001H19.1114C19.5967 13.9953 20.0672 14.1672 20.4352 14.4836C20.8031 14.8001 21.0435 15.2395 21.1114 15.7201C21.238 16.6802 21.4728 17.6228 21.8114 18.5301C21.9459 18.888 21.9751 19.277 21.8953 19.651C21.8155 20.0249 21.6303 20.3682 21.3614 20.6401L20.0914 21.9101C21.515 24.4136 23.5879 26.4865 26.0914 27.9101L27.3614 26.6401C27.6333 26.3712 27.9766 26.1859 28.3505 26.1062C28.7245 26.0264 29.1135 26.0556 29.4714 26.1901C30.3787 26.5286 31.3213 26.7635 32.2814 26.8901C32.7672 26.9586 33.2108 27.2033 33.5279 27.5776C33.8451 27.9519 34.0136 28.4297 34.0014 28.9201Z"
        stroke="#E6F5FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Message = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" fill="#039EF2" />
      <path
        d="M33 23.5C33.0034 24.8199 32.6951 26.1219 32.1 27.3C31.3944 28.7118 30.3098 29.8992 28.9674 30.7293C27.6251 31.5594 26.0782 31.9994 24.5 32C23.1801 32.0035 21.8781 31.6951 20.7 31.1L15 33L16.9 27.3C16.3049 26.1219 15.9966 24.8199 16 23.5C16.0006 21.9218 16.4406 20.3749 17.2707 19.0326C18.1008 17.6903 19.2883 16.6056 20.7 15.9C21.8781 15.305 23.1801 14.9966 24.5 15H25C27.0843 15.115 29.053 15.9948 30.5291 17.4709C32.0052 18.947 32.885 20.9157 33 23V23.5Z"
        stroke="#E6F5FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Mail = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" fill="#039EF2" />
      <path
        d="M34 18C34 16.9 33.1 16 32 16H16C14.9 16 14 16.9 14 18M34 18V30C34 31.1 33.1 32 32 32H16C14.9 32 14 31.1 14 30V18M34 18L24 25L14 18"
        stroke="#E6F5FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Address = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" fill="#039EF2" />
      <path
        d="M33 22C33 29 24 35 24 35C24 35 15 29 15 22C15 19.6131 15.9482 17.3239 17.636 15.636C19.3239 13.9482 21.6131 13 24 13C26.3869 13 28.6761 13.9482 30.364 15.636C32.0518 17.3239 33 19.6131 33 22Z"
        stroke="#E6F5FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 25C25.6569 25 27 23.6569 27 22C27 20.3431 25.6569 19 24 19C22.3431 19 21 20.3431 21 22C21 23.6569 22.3431 25 24 25Z"
        stroke="#E6F5FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Phone;
