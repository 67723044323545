import styled from "styled-components";

interface Image {
  height?: string;
  width?: string;
  LGheight?: string;
}

export const RoundedImage = styled.img<Image>`
  height: ${(props) => props.height || "100%"};
  border-radius: 24px;
  object-fit: cover;
  width: ${(props) => props.width || "100%"};

  @media (min-width: 992px) {
    height: ${(props) => props.LGheight || "100%"};
  }
`;

export interface CoinProps {
  // mobile screen
  topMobile?: string;
  bottomMobile?: string;
  rightMobile?: string;
  leftMobile?: string;
  widthMobile?: string;
  heightMobile?: string;
  // desktop screen
  topDesktop?: string;
  bottomDesktop?: string;
  rightDesktop?: string;
  leftDesktop?: string;
  widthDesktop?: string;
  heightDesktop?: string;
}

export const Coins = styled.img<CoinProps>`
  position: absolute;
  right: ${({ rightMobile }) => rightMobile};
  left: ${({ leftMobile }) => leftMobile};
  top: ${({ topMobile }) => topMobile};
  bottom: ${({ bottomMobile }) => bottomMobile};
  width: ${({ widthMobile }) => widthMobile || 0};
  height: ${({ widthMobile }) => widthMobile || 0};
  z-index: 30;

  @media (min-width: 992px) {
    right: ${({ rightDesktop }) => rightDesktop};
    left: ${({ leftDesktop }) => leftDesktop};
    top: ${({ topDesktop }) => topDesktop};
    bottom: ${({ bottomDesktop }) => bottomDesktop};
    width: ${({ widthDesktop }) => widthDesktop};
    height: ${({ heightDesktop }) => heightDesktop};
  }
`;

export const ModifiedButton = styled.button`
  border-radius: 8px;
  padding: 16px 28px;
  border: 1px solid;
  background: linear-gradient(to bottom, #039ef2, #027ec2);
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  font-family: "Satoshi";
  width: fit-content;
`;

export const Hr = styled.hr`
  border: 1px solid #eaecf0;
  width: 100%;
`;
