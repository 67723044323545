import React from "react";

export default function AppStore() {
  return (
    <svg
      width="132"
      height="45"
      viewBox="0 0 132 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1.09961" width="131" height="43" rx="6.5" fill="black" />
      <rect
        x="0.5"
        y="1.09961"
        width="131"
        height="43"
        rx="6.5"
        stroke="#A6A6A6"
      />
      <path
        d="M89.6793 21.72V24.2401H88.0997V25.8928H89.6793V31.5083C89.6793 33.4258 90.5468 34.1928 92.729 34.1928C93.1125 34.1928 93.4778 34.1471 93.7973 34.0923V32.4579C93.5234 32.4853 93.3499 32.5036 93.0486 32.5036C92.0716 32.5036 91.6425 32.047 91.6425 31.0061V25.8928H93.7973V24.2401H91.6425V21.72H89.6793Z"
        fill="white"
      />
      <path
        d="M99.3566 34.3297C102.26 34.3297 104.041 32.3849 104.041 29.1617C104.041 25.9567 102.251 24.0027 99.3566 24.0027C96.453 24.0027 94.6633 25.9567 94.6633 29.1617C94.6633 32.3849 96.4438 34.3297 99.3566 34.3297ZM99.3566 32.5857C97.6491 32.5857 96.6904 31.3348 96.6904 29.1617C96.6904 27.0068 97.6491 25.7467 99.3566 25.7467C101.055 25.7467 102.023 27.0068 102.023 29.1617C102.023 31.3257 101.055 32.5857 99.3566 32.5857Z"
        fill="white"
      />
      <path
        d="M105.564 34.138H107.527V28.2668C107.527 26.8698 108.577 25.9293 110.066 25.9293C110.413 25.9293 110.997 25.9933 111.161 26.048V24.1123C110.951 24.0575 110.577 24.0301 110.285 24.0301C108.988 24.0301 107.883 24.7423 107.6 25.7193H107.454V24.1945H105.564V34.138Z"
        fill="white"
      />
      <path
        d="M116.036 25.6737C117.488 25.6737 118.437 26.6872 118.483 28.2486H113.461C113.57 26.6963 114.584 25.6737 116.036 25.6737ZM118.474 31.4535C118.108 32.2296 117.296 32.6588 116.109 32.6588C114.538 32.6588 113.525 31.554 113.461 29.81V29.7004H120.482V29.0156C120.482 25.8928 118.812 24.0027 116.045 24.0027C113.242 24.0027 111.461 26.0207 111.461 29.1982C111.461 32.3757 113.205 34.3297 116.054 34.3297C118.328 34.3297 119.916 33.234 120.364 31.4535H118.474Z"
        fill="white"
      />
      <path
        d="M76.8054 30.466C76.9568 32.9077 78.9915 34.4692 82.02 34.4692C85.2566 34.4692 87.2819 32.832 87.2819 30.2199C87.2819 28.1663 86.1273 27.0306 83.326 26.3776L81.8212 26.0085C80.042 25.5921 79.3228 25.0337 79.3228 24.059C79.3228 22.8287 80.4395 22.0242 82.1146 22.0242C83.7045 22.0242 84.8024 22.8097 85.0011 24.0684H87.0642C86.9412 21.7687 84.9159 20.1504 82.143 20.1504C79.1619 20.1504 77.1745 21.7687 77.1745 24.2009C77.1745 26.2073 78.3007 27.3997 80.7707 27.977L82.531 28.4029C84.3386 28.8288 85.1336 29.4534 85.1336 30.4944C85.1336 31.7058 83.8844 32.5859 82.1809 32.5859C80.3543 32.5859 79.0862 31.7626 78.9064 30.466H76.8054Z"
        fill="white"
      />
      <path
        d="M56.4693 24.0301C55.118 24.0301 53.9492 24.7058 53.3466 25.838H53.2005V24.1945H51.3104V37.4434H53.2735V32.6314H53.4287C53.9492 33.6814 55.0723 34.3023 56.4876 34.3023C58.9986 34.3023 60.5965 32.3209 60.5965 29.1617C60.5965 26.0024 58.9986 24.0301 56.4693 24.0301ZM55.9123 32.5401C54.2688 32.5401 53.237 31.2435 53.237 29.1708C53.237 27.089 54.2688 25.7924 55.9215 25.7924C57.5833 25.7924 58.5785 27.0616 58.5785 29.1617C58.5785 31.2709 57.5833 32.5401 55.9123 32.5401Z"
        fill="white"
      />
      <path
        d="M67.4658 24.0301C66.1144 24.0301 64.9457 24.7058 64.343 25.838H64.1969V24.1945H62.3069V37.4434H64.27V32.6314H64.4252C64.9457 33.6814 66.0688 34.3023 67.4841 34.3023C69.995 34.3023 71.5929 32.3209 71.5929 29.1617C71.5929 26.0024 69.995 24.0301 67.4658 24.0301ZM66.9088 32.5401C65.2653 32.5401 64.2335 31.2435 64.2335 29.1708C64.2335 27.089 65.2653 25.7924 66.9179 25.7924C68.5798 25.7924 69.575 27.0616 69.575 29.1617C69.575 31.2709 68.5798 32.5401 66.9088 32.5401Z"
        fill="white"
      />
      <path
        d="M47.7882 34.138H50.0406L45.1099 20.4816H42.8291L37.8984 34.138H40.0751L41.3338 30.5133H46.5389L47.7882 34.138ZM43.8607 22.9612H44.0216L45.9995 28.7341H41.8733L43.8607 22.9612Z"
        fill="white"
      />
      <path
        d="M39.2148 10.1816V16.7695H41.5934C43.5566 16.7695 44.6934 15.5596 44.6934 13.455C44.6934 11.3823 43.5474 10.1816 41.5934 10.1816H39.2148ZM40.2375 11.1129H41.4793C42.8444 11.1129 43.6524 11.9803 43.6524 13.4687C43.6524 14.9798 42.8581 15.8381 41.4793 15.8381H40.2375V11.1129Z"
        fill="white"
      />
      <path
        d="M48.175 16.8654C49.6268 16.8654 50.517 15.8929 50.517 14.2813C50.517 12.6789 49.6222 11.7019 48.175 11.7019C46.7232 11.7019 45.8283 12.6789 45.8283 14.2813C45.8283 15.8929 46.7186 16.8654 48.175 16.8654ZM48.175 15.9934C47.3212 15.9934 46.8419 15.3679 46.8419 14.2813C46.8419 13.2039 47.3212 12.5739 48.175 12.5739C49.0241 12.5739 49.5081 13.2039 49.5081 14.2813C49.5081 15.3633 49.0241 15.9934 48.175 15.9934Z"
        fill="white"
      />
      <path
        d="M58.0984 11.7977H57.1168L56.2311 15.5916H56.1535L55.1308 11.7977H54.1904L53.1677 15.5916H53.0947L52.2044 11.7977H51.2091L52.5788 16.7695H53.5877L54.6104 13.108H54.688L55.7152 16.7695H56.7333L58.0984 11.7977Z"
        fill="white"
      />
      <path
        d="M59.2288 16.7695H60.2103V13.8613C60.2103 13.0852 60.6715 12.6012 61.3974 12.6012C62.1233 12.6012 62.4702 12.9984 62.4702 13.7974V16.7695H63.4518V13.5509C63.4518 12.3684 62.84 11.7019 61.7306 11.7019C60.9819 11.7019 60.4888 12.0351 60.2469 12.5875H60.1738V11.7977H59.2288V16.7695Z"
        fill="white"
      />
      <path
        d="M64.9977 16.7695H65.9792V9.85742H64.9977V16.7695Z"
        fill="white"
      />
      <path
        d="M69.6708 16.8654C71.1226 16.8654 72.0129 15.8929 72.0129 14.2813C72.0129 12.6789 71.1181 11.7019 69.6708 11.7019C68.219 11.7019 67.3242 12.6789 67.3242 14.2813C67.3242 15.8929 68.2145 16.8654 69.6708 16.8654ZM69.6708 15.9934C68.8171 15.9934 68.3377 15.3679 68.3377 14.2813C68.3377 13.2039 68.8171 12.5739 69.6708 12.5739C70.52 12.5739 71.0039 13.2039 71.0039 14.2813C71.0039 15.3633 70.52 15.9934 69.6708 15.9934Z"
        fill="white"
      />
      <path
        d="M74.9375 16.0253C74.4034 16.0253 74.0153 15.7651 74.0153 15.3177C74.0153 14.8794 74.3257 14.6466 75.0106 14.6009L76.225 14.5233V14.9387C76.225 15.5551 75.6771 16.0253 74.9375 16.0253ZM74.6864 16.8517C75.3393 16.8517 75.8825 16.5686 76.1793 16.071H76.2569V16.7695H77.202V13.3728C77.202 12.3228 76.4989 11.7019 75.2525 11.7019C74.1249 11.7019 73.3213 12.2497 73.2209 13.1034H74.1705C74.2801 12.7519 74.659 12.551 75.2069 12.551C75.878 12.551 76.225 12.8478 76.225 13.3728V13.802L74.8782 13.8796C73.6957 13.9526 73.0292 14.4685 73.0292 15.3633C73.0292 16.2719 73.7277 16.8517 74.6864 16.8517Z"
        fill="white"
      />
      <path
        d="M80.5329 16.8517C81.2177 16.8517 81.7975 16.5275 82.0943 15.9842H82.1719V16.7695H83.1124V9.85742H82.1308V12.5875H82.0578C81.7884 12.0397 81.2131 11.7156 80.5329 11.7156C79.2774 11.7156 78.4693 12.7108 78.4693 14.2813C78.4693 15.8564 79.2683 16.8517 80.5329 16.8517ZM80.8114 12.5967C81.6332 12.5967 82.1491 13.2495 82.1491 14.2859C82.1491 15.3268 81.6377 15.9705 80.8114 15.9705C79.9805 15.9705 79.4828 15.3359 79.4828 14.2813C79.4828 13.2358 79.985 12.5967 80.8114 12.5967Z"
        fill="white"
      />
      <path
        d="M89.4775 16.8654C90.9293 16.8654 91.8196 15.8929 91.8196 14.2813C91.8196 12.6789 90.9247 11.7019 89.4775 11.7019C88.0257 11.7019 87.1309 12.6789 87.1309 14.2813C87.1309 15.8929 88.0211 16.8654 89.4775 16.8654ZM89.4775 15.9934C88.6238 15.9934 88.1444 15.3679 88.1444 14.2813C88.1444 13.2039 88.6238 12.5739 89.4775 12.5739C90.3267 12.5739 90.8106 13.2039 90.8106 14.2813C90.8106 15.3633 90.3267 15.9934 89.4775 15.9934Z"
        fill="white"
      />
      <path
        d="M93.1189 16.7695H94.1004V13.8613C94.1004 13.0852 94.5616 12.6012 95.2875 12.6012C96.0134 12.6012 96.3603 12.9984 96.3603 13.7974V16.7695H97.3419V13.5509C97.3419 12.3684 96.7301 11.7019 95.6207 11.7019C94.872 11.7019 94.3789 12.0351 94.137 12.5875H94.0639V11.7977H93.1189V16.7695Z"
        fill="white"
      />
      <path
        d="M101.863 10.5605V11.8206H101.073V12.6469H101.863V15.4546C101.863 16.4134 102.296 16.7969 103.387 16.7969C103.579 16.7969 103.762 16.774 103.922 16.7467V15.9294C103.785 15.9431 103.698 15.9523 103.547 15.9523C103.059 15.9523 102.844 15.724 102.844 15.2035V12.6469H103.922V11.8206H102.844V10.5605H101.863Z"
        fill="white"
      />
      <path
        d="M105.239 16.7695H106.221V13.8659C106.221 13.1126 106.668 12.6058 107.472 12.6058C108.166 12.6058 108.535 13.0076 108.535 13.802V16.7695H109.517V13.56C109.517 12.3775 108.864 11.7064 107.805 11.7064C107.056 11.7064 106.531 12.0397 106.289 12.5967H106.212V9.85742H105.239V16.7695Z"
        fill="white"
      />
      <path
        d="M113.058 12.5373C113.784 12.5373 114.259 13.0441 114.281 13.8248H111.77C111.825 13.0487 112.332 12.5373 113.058 12.5373ZM114.277 15.4272C114.094 15.8153 113.688 16.0299 113.094 16.0299C112.309 16.0299 111.802 15.4775 111.77 14.6055V14.5507H115.281V14.2083C115.281 12.6469 114.446 11.7019 113.062 11.7019C111.661 11.7019 110.771 12.7108 110.771 14.2996C110.771 15.8884 111.643 16.8654 113.067 16.8654C114.204 16.8654 114.998 16.3175 115.222 15.4272H114.277Z"
        fill="white"
      />
      <path
        d="M27.2458 22.9298C27.2696 21.0807 28.2627 19.331 29.838 18.3625C28.8442 16.9432 27.1796 16.0433 25.4477 15.9891C23.6006 15.7952 21.8098 17.0944 20.8685 17.0944C19.909 17.0944 18.4598 16.0083 16.8992 16.0404C14.865 16.1062 12.9687 17.2627 11.979 19.0411C9.85158 22.7244 11.4384 28.1375 13.4763 31.1148C14.4959 32.5727 15.6875 34.2012 17.2467 34.1434C18.7725 34.0801 19.3423 33.1705 21.184 33.1705C23.0087 33.1705 23.5433 34.1434 25.1341 34.1067C26.7714 34.0801 27.8029 32.6423 28.7867 31.1706C29.5193 30.1319 30.083 28.9838 30.457 27.7689C28.5329 26.9551 27.248 25.0188 27.2458 22.9298Z"
        fill="white"
      />
      <path
        d="M24.241 14.0311C25.1336 12.9595 25.5734 11.5821 25.4669 10.1914C24.1031 10.3346 22.8433 10.9865 21.9386 12.017C21.0539 13.0238 20.5935 14.377 20.6805 15.7145C22.0448 15.7286 23.386 15.0944 24.241 14.0311Z"
        fill="white"
      />
    </svg>
  );
}
