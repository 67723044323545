import React from "react";

export default function LinkedIn() {
  return (
    <a
      href="https://www.linkedin.com/company/dataup-gaming-limited/"
      target="_blank"
      rel="noreferrer"
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.5996C0 19.227 5.37258 24.5996 12 24.5996C18.6274 24.5996 24 19.227 24 12.5996C24 5.97219 18.6274 0.599609 12 0.599609C5.37258 0.599609 0 5.97219 0 12.5996Z"
          fill="#2867B2"
        />
        <path
          d="M8.7 18.5996H6.15V10.5746H8.7V18.5996ZM7.425 9.44961C6.6 9.44961 6 8.84961 6 8.02461C6 7.19961 6.675 6.59961 7.425 6.59961C8.25 6.59961 8.85 7.19961 8.85 8.02461C8.85 8.84961 8.25 9.44961 7.425 9.44961ZM18 18.5996H15.45V14.2496C15.45 12.9746 14.925 12.5996 14.175 12.5996C13.425 12.5996 12.675 13.1996 12.675 14.3246V18.5996H10.125V10.5746H12.525V11.6996C12.75 11.1746 13.65 10.3496 14.925 10.3496C16.35 10.3496 17.85 11.1746 17.85 13.6496V18.5996H18Z"
          fill="white"
        />
      </svg>
    </a>
  );
}
