import React from "react";

function Twitter() {
  return (
    <a href="https://x.com/DataupGaming" target="_blank" rel="noreferrer">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.5996C0 19.227 5.37258 24.5996 12 24.5996C18.6274 24.5996 24 19.227 24 12.5996C24 5.97219 18.6274 0.599609 12 0.599609C5.37258 0.599609 0 5.97219 0 12.5996Z"
          fill="#1DA1F2"
        />
        <path
          d="M18 8.84961C17.55 9.07461 17.1 9.14961 16.575 9.22461C17.1 8.92461 17.475 8.47461 17.625 7.87461C17.175 8.17461 16.65 8.32461 16.05 8.47461C15.6 8.02461 14.925 7.72461 14.25 7.72461C12.675 7.72461 11.475 9.22461 11.85 10.7246C9.825 10.6496 8.025 9.67461 6.75 8.17461C6.075 9.29961 6.45 10.7246 7.5 11.4746C7.125 11.4746 6.75 11.3246 6.375 11.1746C6.375 12.2996 7.2 13.3496 8.325 13.6496C7.95 13.7246 7.575 13.7996 7.2 13.7246C7.5 14.6996 8.4 15.4496 9.525 15.4496C8.625 16.1246 7.275 16.4996 6 16.3496C7.125 17.0246 8.4 17.4746 9.75 17.4746C14.325 17.4746 16.875 13.6496 16.725 10.1246C17.25 9.82461 17.7 9.37461 18 8.84961Z"
          fill="white"
        />
      </svg>
    </a>
  );
}

export default Twitter;
