import styled from "styled-components";
import Text from "../../components/Text/Text";
import { useTranslation } from "react-i18next";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";
import TextSize, { FontFamily } from "../../components/Text/TextEnum";
import Button from "../../components/Button/Button";
import { ButtonSize, ButtonType } from "../../components/Button/types";
import { SectionProps } from "./SectionTwo";

const SectionSix: React.FC<SectionProps> = ({ id }) => {
  const { t } = useTranslation();
  const themeColors = useThemeColors();

  return (
    <SectionWrapper id={id}>
      <SectionOne backgroundColor={themeColors.SECTION_SIX1_BG}>
        <Text
          fontSize={TextSize.TS24}
          fontSizeLg={TextSize.TS48}
          fontFamily={FontFamily.LT_RENOVATE}
          color={themeColors.SECTION_SIX1_TEXT}
          fontWeight={700}
        >
          {t("sectionSix.recharge")}
        </Text>
        <Button size={ButtonSize.XL} type={ButtonType.FILLED} minWidth="221px">
          {t("sectionSix.button")}
        </Button>
      </SectionOne>
      <SectionTwo backgroundColor={themeColors.SECTION_SIX2_BG}>
        <Image src="/images/section-six.png" />
      </SectionTwo>
    </SectionWrapper>
  );
};

export default SectionSix;

const SectionWrapper = styled.div.attrs<SectionProps>((props) => ({
  id: props.id, // Forward the id prop
}))`
  padding: 64px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 992px) {
    padding: 172px 151px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 792px;
    position: relative;
    /* border-bottom: 1px solid #d0d5dd; */
  }
`;

const SectionOne = styled.div<Props>`
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 24px;

  @media (min-width: 992px) {
    height: 666px;
    padding: 0 88px;
    align-items: start;
    width: 100%;
  }
`;

interface Props {
  backgroundColor?: string;
}

const SectionTwo = styled.div<Props>`
  overflow: hidden;
  border-radius: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 100%;
  display: grid;
  place-items: end start;

  @media (min-width: 992px) {
    height: 666px;
    width: 100%;
  }
`;

const Image = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 666px;
  border-radius: 24px;
`;
