import React from "react";
import styled from "styled-components";
import Text, { GradientText } from "../../components/Text/Text";
import { Trans, useTranslation } from "react-i18next";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";
import TextSize, { FontFamily } from "../../components/Text/TextEnum";

import { SectionProps } from "./SectionTwo";
import Phone, {
  Address,
  Mail,
  Message,
} from "../../components/Icons/ContactIcons";
import { Coins } from "./Definitions";

const SectionEight: React.FC<SectionProps> = ({ id }) => {
  const { t } = useTranslation();
  const themeColors = useThemeColors();
  const preText = "sectionEight.contactDetails.";

  const contacts = [
    {
      logo: <Phone />,
      title: preText + "call.title",
      availability: preText + "call.availability",
      address: preText + "call.address",
    },
    {
      logo: <Message />,
      title: preText + "chat.title",
      availability: preText + "chat.availability",
      address: preText + "chat.address",
    },
    {
      logo: <Mail />,
      title: preText + "mail.title",
      availability: preText + "mail.availability",
      address: preText + "mail.address",
    },
    {
      logo: <Address />,
      title: preText + "address.title",
      availability: preText + "address.availability",
      address: preText + "address.address",
    },
  ];

  return (
    <SectionEightWrapper id={id}>
      <Coins
        src="/images/coin.png"
        topDesktop="276px"
        leftDesktop="0px"
        widthDesktop="61px"
        heightDesktop="83px"
        topMobile="204px"
        leftMobile="0px"
        widthMobile="61px"
        heightMobile="83px"
      />
      <Coins
        src="/images/coin.png"
        topDesktop="0px"
        rightDesktop="380px"
        widthDesktop="61px"
        heightDesktop="83px"
      />

      <Coins
        src="/images/coin.png"
        topDesktop="242px"
        rightDesktop="30px"
        widthDesktop="61px"
        heightDesktop="83px"
      />
      <SectionEightOne>
        <SectionEightOneText>
          <GradientText
            themeColors={themeColors}
            fontSize={TextSize.TS16}
            fontSizeLg={TextSize.TS16}
            fontWeight={600}
          >
            {t("sectionEight.contact")}
          </GradientText>
          <Text
            themeColors={themeColors}
            fontSize={TextSize.TS24}
            fontSizeLg={TextSize.TS36}
            fontWeight={600}
            color={themeColors.TEXT_DARK_3}
          >
            <Trans t={t}>{t("sectionEight.contactSubtitle")}</Trans>
          </Text>
        </SectionEightOneText>
        <Text
          fontFamily={FontFamily.LT_RENOVATE}
          themeColors={themeColors}
          fontSize={TextSize.TS14}
          fontSizeLg={TextSize.TS18}
          fontWeight={400}
          color={themeColors.SECTION_EIGHT_TEXT}
        >
          {t("sectionEight.supportTeam")}
        </Text>
      </SectionEightOne>
      <ContactsSection>
        {contacts.map((contact, index) => (
          <ContactCard key={index}>
            <Logo>{contact.logo}</Logo>
            <ContactCardInfo>
              <Text
                themeColors={themeColors}
                fontSize={TextSize.TS16}
                fontSizeLg={TextSize.TS18}
                fontWeight={600}
                color={themeColors.TEXT_DARK}
              >
                {t(contact.title)}
              </Text>
              <AvailableAddress>
                <Text
                  themeColors={themeColors}
                  fontSize={TextSize.TS12}
                  fontSizeLg={TextSize.TS16}
                  fontWeight={400}
                  color={themeColors.TEXT_GRADIENT_END}
                >
                  {t(contact.availability)}
                </Text>
                <Text
                  themeColors={themeColors}
                  fontSize={TextSize.TS14}
                  fontSizeLg={TextSize.TS16}
                  fontWeight={600}
                  color={themeColors.TEXT_GRADIENT_END}
                >
                  {t(contact.address)}
                </Text>
              </AvailableAddress>
            </ContactCardInfo>
          </ContactCard>
        ))}
      </ContactsSection>
    </SectionEightWrapper>
  );
};

export default SectionEight;

const SectionEightWrapper = styled.div.attrs<SectionProps>((props) => ({
  id: props.id,
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 56px 16px;
  gap: 32px;

  @media (min-width: 992px) {
    padding: 56px 112px;
  }
`;

const SectionEightOne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 992px) {
    width: 768px;
  }
`;

const SectionEightOneText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContactsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 8px;

  & > * {
    flex: 1 1 calc(50% - 24px); /* Two items per row with 24px gap between */
    width: calc(50% - 24px);
  }

  @media (min-width: 992px) {
    flex: nowrap;
    flex-direction: row;
    gap: 24px;

    & > * {
      flex: 1;
      width: calc(25% - 24px); /* Reset width to auto to accommodate flex */
    }
  }
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  border-radius: 24px;
  padding: 16px;
  background-color: #e6f5fe;
  z-index: 50;

  @media (min-width: 992px) {
    padding: 24px;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: #039ef2;
`;

const ContactCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 992px) {
    gap: 16px;
  }
`;

const AvailableAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
