export type ThemeColors = {
  TEXT_PRIMARY: string;
  TEXT_SECONDARY: string;
  TEXT_GRADIENT_START: string;
  TEXT_GRADIENT_END: string;
  TEXT_GRAY: string;
  BACKGROUND: string;
  TEXT_DARK: string;
  TEXT_LIGHT: string;
  TEXT_SHADE: string;
  TEXT_DARK_2: string;
  BUTTON_FILLED_TEXT_COLOR: string;
  BUTTON_FILLED_TEXT_COLOR_DISABLED: string;
  BUTTON_FILLED_BORDER_COLOR: string;
  BUTTON_FILLED_BORDER_COLOR_DISABLED: string;
  BUTTON_FILLED_BACKGROUND_COLOR: string;
  BUTTON_FILLED_BACKGROUND_COLOR_DISABLED: string;
  BUTTON_OUTLINE_TEXT_COLOR: string;
  BUTTON_OUTLINE_TEXT_COLOR_DISABLED: string;
  BUTTON_OUTLINE_BORDER_COLOR: string;
  BUTTON_OUTLINE_BORDER_COLOR_DISABLED: string;
  BUTTON_DESTRUCTIVE_TEXT_COLOR: string;
  BUTTON_DESTRUCTIVE_TEXT_COLOR_DISABLED: string;
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR: string;
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR_DISABLED: string;
  NAVIGATION_BG: string;
  NAV_ICONS: string;
  SECTION_THREE2_BG: string;
  SECTION_THREE_TOGGLE_BORDER: string;
  SECTION_SIX1_BG: string;
  SECTION_SIX1_TEXT: string;
  SECTION_SIX2_BG: string;
  SECTION_SEVEN_TEXT: string;
  SECTION_EIGHT_TEXT: string;
  TEXT_DARK_3: string;
  UP_BUTTON: string;
};

export enum Theme {
  DARK = "dark",
  LIGHT = "light",
  SYSTEM = "system",
}

export const DarkTheme: ThemeColors = {
  TEXT_PRIMARY: "#039EF2",
  TEXT_SECONDARY: "#FFFFFF",
  TEXT_GRADIENT_START: "#039EF2",
  TEXT_GRADIENT_END: "#027EC2",
  TEXT_GRAY: "#667085",
  BACKGROUND: "#00070B",
  TEXT_DARK: "#000000",
  TEXT_LIGHT: "#FFFFFF",
  TEXT_SHADE: "#FCFCFD",
  TEXT_DARK_2: "#FFFFFF",
  TEXT_DARK_3: "#FFFFFF",
  BUTTON_FILLED_TEXT_COLOR: "#FFFFFF",
  BUTTON_FILLED_TEXT_COLOR_DISABLED: "#FFFFFF",
  BUTTON_FILLED_BACKGROUND_COLOR:
    "linear-gradient(180deg, #039EF2 0%, #027EC2 100%)",
  BUTTON_FILLED_BACKGROUND_COLOR_DISABLED: "#E6F5FE",
  BUTTON_FILLED_BORDER_COLOR:
    "linear-gradient(180deg, #039EF2 0%, #027EC2 100%)",
  BUTTON_FILLED_BORDER_COLOR_DISABLED: "#E6F5FE",
  BUTTON_OUTLINE_TEXT_COLOR: "#FFFFFF",
  BUTTON_OUTLINE_TEXT_COLOR_DISABLED: "#D0D5DD",
  BUTTON_OUTLINE_BORDER_COLOR: "#D0D5DD",
  BUTTON_OUTLINE_BORDER_COLOR_DISABLED: "#EAECF0",
  BUTTON_DESTRUCTIVE_TEXT_COLOR: "#F04438",
  BUTTON_DESTRUCTIVE_TEXT_COLOR_DISABLED: "#F04438",
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR: "#FEF3F2",
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR_DISABLED: "#FEF3F2",
  NAVIGATION_BG: "#00070B",
  NAV_ICONS: "#F9FAFB",
  SECTION_THREE2_BG: "#001724",
  SECTION_THREE_TOGGLE_BORDER: "#00070B",
  SECTION_SIX1_BG: "#F9F5FF",
  SECTION_SIX1_TEXT: "#012030",
  SECTION_SIX2_BG: "#E6F5FE",
  SECTION_SEVEN_TEXT: "#F9FAFB",
  SECTION_EIGHT_TEXT: "#FCFCFD",
  UP_BUTTON: "#101828",
};

export const LightTheme: ThemeColors = {
  TEXT_PRIMARY: "#039EF2",
  TEXT_SECONDARY: "#FFFFFF",
  TEXT_GRADIENT_START: "#039EF2",
  TEXT_GRADIENT_END: "#027EC2",
  TEXT_GRAY: "#667085",
  BACKGROUND: "#FFFFFF",
  TEXT_DARK: "#000000",
  TEXT_LIGHT: "#FFFFFF",
  TEXT_SHADE: "#757575",
  TEXT_DARK_2: "#101928",
  TEXT_DARK_3: "#00070B",
  BUTTON_FILLED_TEXT_COLOR: "#FFFFFF",
  BUTTON_FILLED_TEXT_COLOR_DISABLED: "#FFFFFF",
  BUTTON_FILLED_BACKGROUND_COLOR:
    "linear-gradient(180deg, #039EF2 0%, #027EC2 100%)",
  BUTTON_FILLED_BACKGROUND_COLOR_DISABLED: "#E6F5FE",
  BUTTON_FILLED_BORDER_COLOR:
    "linear-gradient(180deg, #039EF2 0%, #027EC2 100%)",
  BUTTON_FILLED_BORDER_COLOR_DISABLED: "#E6F5FE",
  BUTTON_OUTLINE_TEXT_COLOR: "#00070B",
  BUTTON_OUTLINE_TEXT_COLOR_DISABLED: "#D0D5DD",
  BUTTON_OUTLINE_BORDER_COLOR: "#D0D5DD",
  BUTTON_OUTLINE_BORDER_COLOR_DISABLED: "#EAECF0",
  BUTTON_DESTRUCTIVE_TEXT_COLOR: "#F04438",
  BUTTON_DESTRUCTIVE_TEXT_COLOR_DISABLED: "#F04438",
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR: "#FEF3F2",
  BUTTON_DESTRUCTIVE_BACKGROUND_COLOR_DISABLED: "#FEF3F2",
  NAVIGATION_BG: "#FFFFFF",
  NAV_ICONS: "#00070B",
  SECTION_THREE2_BG: "#FFFFFF",
  SECTION_THREE_TOGGLE_BORDER: "#F2F4F7",
  SECTION_SIX1_BG: "#F9F5FF",
  SECTION_SIX1_TEXT: "#012030",
  SECTION_SIX2_BG: "#E6F5FE",
  SECTION_SEVEN_TEXT: "#475467",
  SECTION_EIGHT_TEXT: "#757575",
  UP_BUTTON: "#F2F4F7",
};
