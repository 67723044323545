import styled from "styled-components";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";
import Text, { TextWrapper } from "../Text/Text";
import TextSize, { FontFamily } from "../Text/TextEnum";
import { HorizontalLayout, VerticalLayout } from "../Layout";
import { ThemeColors } from "../../providers/ThemeProvider/colors";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

type NavigationData = {
  title: string;
  description: string;
  image: string;
};

type TabNavigationProps = {
  readonly data: NavigationData[];
};

function TabNavigation({ data }: TabNavigationProps) {
  const [index, setIndex] = useState(0);
  const switchRef = useRef<number>();
  const themeColors = useThemeColors();
  const { t } = useTranslation();

  useEffect(() => {
    switchRef.current = window.setInterval(() => {
      setIndex((prev) => (prev + 1) % data.length);
    }, 7000);
    return () => {
      window.clearTimeout(switchRef.current);
    };
  }, [data.length]);

  const onClick = (index: number) => {
    setIndex(index);
  };

  const mobileView = (
    <NavigationWrapperMobile>
      <BottomSectionVertical>
        <div style={{ maxWidth: 601, height: 400 }}>
          <AnimatePresence>
            {data.map((item, i) => {
              const selected = index === i;
              return (
                <HeaderMobileVerticallayout
                  key={item.title}
                  themeColors={themeColors}
                  selected={selected}
                  onClick={() => onClick?.(i)}
                >
                  <Text
                    themeColors={themeColors}
                    color={
                      selected
                        ? themeColors.TEXT_PRIMARY
                        : themeColors.TEXT_LIGHT
                    }
                    fontSize={TextSize.TS18}
                    fontWeight={600}
                  >
                    {t(item.title)}
                  </Text>
                  <AnimatePresence>
                    {selected && (
                      <DescriptionText
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        isLast={i === data.length - 1}
                        themeColors={themeColors}
                        color={themeColors.TEXT_LIGHT}
                        fontSize={TextSize.TS16}
                        fontFamily={FontFamily.SATOSHI}
                        fontWeight={400}
                      >
                        {t(item.description)}
                      </DescriptionText>
                    )}
                  </AnimatePresence>
                </HeaderMobileVerticallayout>
              );
            })}
          </AnimatePresence>
        </div>
        <div style={{ maxWidth: 486, height: "auto" }}>
          <img
            draggable={false}
            src={data[index].image}
            alt="section-two"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </BottomSectionVertical>
    </NavigationWrapperMobile>
  );

  const webView = (
    <NavigationWrapperWeb>
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          borderBottom: "2px solid",
          borderColor: themeColors.TEXT_GRAY,
          marginBottom: "20px",
        }}
      >
        <AnimatePresence>
          {data.map((item, i) => {
            const selected = index === i;

            return (
              <HeaderWebHorizontallayout
                key={item.title}
                themeColors={themeColors}
                selected={selected}
                onClick={() => onClick?.(i)}
              >
                <Text
                  themeColors={themeColors}
                  color={
                    selected ? themeColors.TEXT_LIGHT : themeColors.TEXT_GRAY
                  }
                  fontSize={TextSize.TS18}
                  fontWeight={selected ? 600 : 400}
                  display="inline"
                >
                  {t(item.title)}
                </Text>
              </HeaderWebHorizontallayout>
            );
          })}
        </AnimatePresence>
      </div>
      <BottomSectionHorizontal>
        <div style={{ maxWidth: 601, height: 400 }}>
          <AnimatePresence>
            <DescriptionTextHorizontal
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              isLast={index === data.length - 1}
              themeColors={themeColors}
              color={themeColors.TEXT_LIGHT}
              fontSize={TextSize.TS36}
              fontFamily={FontFamily.LT_RENOVATE}
              fontWeight={600}
            >
              {t(data[index].description)}
            </DescriptionTextHorizontal>
          </AnimatePresence>
        </div>
        <div style={{ maxWidth: 486, height: "auto" }}>
          <img
            draggable={false}
            src={data[index].image}
            alt="section-two"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </BottomSectionHorizontal>
    </NavigationWrapperWeb>
  );

  return (
    <>
      {mobileView}
      {webView}
    </>
  );
}

export default TabNavigation;

const NavigationWrapperWeb = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;

const NavigationWrapperMobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

const BottomSectionVertical = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 992px) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
`;
const BottomSectionHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 992px) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const HeaderMobileVerticallayout = styled(VerticalLayout)<{
  selected: boolean;
  themeColors: ThemeColors;
}>`
  border-left: 4px solid
    ${({ selected, themeColors }) =>
      selected ? themeColors.TEXT_PRIMARY : themeColors.TEXT_LIGHT};
  padding: 16px 0 16px 24px;
  cursor: pointer;
`;

const DescriptionText = styled(motion(TextWrapper))<{ isLast: boolean }>`
  display: -webkit-box;
  overflow: hidden;
`;

const HeaderWebHorizontallayout = styled(HorizontalLayout)<{
  selected: boolean;
  themeColors: ThemeColors;
}>`
  border-bottom: ${({ selected, themeColors }) =>
    selected && "2px solid" + themeColors.TEXT_LIGHT};
  padding: 0 0 16px 0;
  cursor: pointer;
  display: inline;
`;
const DescriptionTextHorizontal = styled(motion(TextWrapper))<{
  isLast: boolean;
}>`
  display: -webkit-box;
  overflow: hidden;
  margin-top: 150px;
`;
