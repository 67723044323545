import React from "react";

function Facebook() {
  return (
    <a
      href="https://www.facebook.com/share/Jy2W7yakiSxjde2V/?mibextid=LQQJ4d"
      target="_blank"
      rel="noreferrer"
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.5996C0 19.227 5.37258 24.5996 12 24.5996C18.6274 24.5996 24 19.227 24 12.5996C24 5.97219 18.6274 0.599609 12 0.599609C5.37258 0.599609 0 5.97219 0 12.5996Z"
          fill="#1877F2"
        />
        <path
          d="M18 12.5996C18 9.29961 15.3 6.59961 12 6.59961C8.7 6.59961 6 9.29961 6 12.5996C6 15.5996 8.175 18.0746 11.025 18.5246V14.3246H9.525V12.5996H11.025V11.2496C11.025 9.74961 11.925 8.92461 13.275 8.92461C13.95 8.92461 14.625 9.07461 14.625 9.07461V10.5746H13.875C13.125 10.5746 12.9 11.0246 12.9 11.4746V12.5996H14.55L14.25 14.3246H12.825V18.5996C15.825 18.1496 18 15.5996 18 12.5996Z"
          fill="white"
        />
      </svg>
    </a>
  );
}

export default Facebook;
