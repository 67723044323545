import styled from "styled-components";
import TextSize, { FontFamily } from "../../components/Text/TextEnum";
import Text, { GradientText } from "../../components/Text/Text";
import { Trans, useTranslation } from "react-i18next";
import { useThemeColors } from "../../providers/ThemeProvider/ThemeProvider";
import { Hr, RoundedImage } from "./Definitions";
import Button from "../../components/Button/Button";
import { ButtonSize, ButtonType } from "../../components/Button/types";

import { VerticalLayout } from "../../components/Layout";
import { SectionProps } from "./SectionTwo";
import { Link } from "react-router-dom";

const SectionSeven: React.FC<SectionProps> = ({ id }) => {
  const { t } = useTranslation();
  const themeColors = useThemeColors();

  const partnershipBenefits = [
    t("sectionSeven.benefitsOfPartnership.benefits.0"),
    t("sectionSeven.benefitsOfPartnership.benefits.1"),
    t("sectionSeven.benefitsOfPartnership.benefits.2"),
  ];

  return (
    <SectionSevenWrapper id={id}>
      <SectionSevenInner>
        <SectionSevenOne>
          <SectionSevenOneText>
            <GradientText
              themeColors={themeColors}
              fontSize={TextSize.TS16}
              fontSizeLg={TextSize.TS16}
              fontWeight={600}
            >
              {t("sectionSeven.partnership")}
            </GradientText>
            <Text
              themeColors={themeColors}
              fontSize={TextSize.TS24}
              fontSizeLg={TextSize.TS36}
              fontWeight={600}
              color={themeColors.SECTION_SEVEN_TEXT}
            >
              <Trans t={t}>{t("sectionSeven.gamingPatner")}</Trans>
            </Text>
          </SectionSevenOneText>
          <Text
            fontFamily={FontFamily.SATOSHI}
            themeColors={themeColors}
            fontSize={TextSize.TS14}
            fontSizeLg={TextSize.TS18}
            color={themeColors.SECTION_SEVEN_TEXT}
          >
            {t("sectionSeven.gamingPatnerSubtitle")}
          </Text>
          <StyledLink to="mailto:info@dataupgaming.com">
            <CenteredButton>
              <Button
                size={ButtonSize.XL}
                type={ButtonType.FILLED}
                minWidth="264px"
              >
                {t("sectionSeven.button")}
              </Button>
            </CenteredButton>
          </StyledLink>
        </SectionSevenOne>

        <SectionSevenCenter distance="24px">
          <RoundedImage
            height="588px"
            width="100%"
            src="/images/section-seven-img.jpeg"
          />
        </SectionSevenCenter>
        <SectionSevenBenefits>
          <SectionSevenBenefitsInner>
            <GradientText
              themeColors={themeColors}
              fontSize={TextSize.TS16}
              fontSizeLg={TextSize.TS16}
              fontWeight={600}
            >
              {t("sectionSeven.benefitsOfPartnership.title")}
            </GradientText>
            <SectionSevenBenefitTexts>
              {partnershipBenefits.map((benefit, index) => (
                <>
                  <Text
                    key={index + benefit}
                    themeColors={themeColors}
                    fontSize={TextSize.TS24}
                    fontSizeLg={TextSize.TS24}
                    color={themeColors.SECTION_SEVEN_TEXT}
                  >
                    {benefit}
                  </Text>
                  {index < partnershipBenefits.length - 1 && <Hr />}
                </>
              ))}
            </SectionSevenBenefitTexts>
          </SectionSevenBenefitsInner>
        </SectionSevenBenefits>
      </SectionSevenInner>
    </SectionSevenWrapper>
  );
};

export default SectionSeven;

const SectionSevenWrapper = styled.div.attrs<SectionProps>((props) => ({
  id: props.id, // Forward the id prop
}))`
  padding: 56px 16px;

  @media (min-width: 992px) {
    padding: 120px 151px 172px 151px;
    max-height: 924px;
  }
`;

const SectionSevenInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (min-width: 992px) {
    position: relative;
    height: 588px;
    flex-direction: row;
  }
`;

const SectionSevenOne = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

const SectionSevenOneText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  gap: 12px;
`;

const SectionSevenCenter = styled(VerticalLayout)`
  position: relative;

  width: 100%;

  @media (min-width: 992px) {
    max-width: 418px;
  }
`;

const SectionSevenBenefits = styled.div`
  height: 588px;
  align-items: start;
  padding: 41px 0px;
  gap: 24px;
  width: 100%;
`;

const SectionSevenBenefitsInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  @media (min-width: 992px) {
    justify-content: center;
    align-items: start;
    padding-left: 32px;
  }
`;

const SectionSevenBenefitTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 100%;
`;

const CenteredButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 992px) {
    display: block;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

